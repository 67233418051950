import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="background-logo.jpg" alt="logo" />
        <p>
          Lolilore 📚 WIP
        </p>
        <ol title="Loli Lores">
            <li>What loli says is truth</li>
            <li>You must do what loli says</li>
            <li>Onii-chan no baka!</li>
        </ol>
      </header>
    </div>
  );
}

export default App;
